body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  height: 100%;
  transition: all 0.5s ease;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

#root {
  height: 100%;
}

hr {
  width: 50%;
  border: 0;
  height: 2px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
}

/*.particles-bg-canvas-self{*/
/*  background: #171515;*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
