.social-button.twitter {
    background-color: #1DA1F2;
}

.social-button.instagram {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.social-button.linkedin {
    background-color: #0077b5;
}

.social-button.github {
    background-color: #171515;
}

.social-button.scholar img {
    width: 55%;
    vertical-align: middle;
}

.social-button.mail img {
    width: 55%;
    vertical-align: middle;
}

.social-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.social-container a { color: inherit; }

.social-button {
    display: inline-block;
    background-color: #fff;
    width: 90px;
    height: 90px;
    line-height: 90px;
    margin: 2%;
    text-align: center;
    position: relative;
    overflow: hidden;
    opacity: .99;
    border-radius: 28%;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
}

.social-button:hover {
    transform: scale(1.1);
    box-shadow: 4px 0 15px -4px black, -6px 16px 19px -3px black;
}

.social-button .fa{
    vertical-align: middle;
}

@media only screen and (max-width: 1000px) {
    .social-button {
        margin-bottom: 20px;
    }

    .social-container{
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-evenly;
    }

    .fa {
        color: white
    }

    .contact-form {
        width: 90%;
    }



}