.blog {
    color: white;
    background-color:  rgba(128,128,128, 0.2);
    margin: 2%;
    padding: 2%;
    transition: all 1s ease-in;
    text-align: justify;
    display: grid;
    grid-template-columns: 0.5fr 3fr 1fr;
}

.blog-right {
    border-left: 1px solid ghostwhite;
}

.blog-middle {
    padding-left: 5%;
    padding-right: 15%;
}