.contact {
    color: white;
    background-color:  rgba(128,128,128, 0.2);
    margin: 2%;
    padding: 2%;
    transition: all 1s ease-in;
}

.contact h1 {
    color: #f6a231;
}


.contact-form{
    color: black;
    background-color: #f6a231;
    display: inline-block;
    border-radius: 10px;
    justify-content: center;
    width: 50%;
}

.contact-form p {
    font-weight: 600;
    font-size: larger;
}