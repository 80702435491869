.navbar {
    background: rgba(128,128,128, 0.4);
    height: 3.5rem;
    width: 100%;
    position: fixed;
    z-index: 10;
}

.scroll {
    background: rgb(103, 103, 103);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2)
}

.navbar-left * {
    float: left;
    color: white;
    display: inline-block;
}
.navbar-brand {
    width: 50px;
    padding-left: 10px;
    padding-top: 5px;
    min-width: 40px;
}

.navbar-left:hover {
    cursor: pointer;
}

.navbar-name {
    padding-left: 20px;
    font-weight: 900;
    margin: 0px;
    padding-top: 15px;
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.navbar-right * {
    float: right;
    color: white;
    display: inline-block;
}

.navbar-routes .link {
    margin-right: 20px;
    font-weight: bold;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid white;
    transition: all 0.5s;
}

.navbar-routes .link:hover {
    background-color: white;
    color: black;
    cursor: pointer;
    border: 1px solid white;
}

.navbar-routes .link.active {
    color: black;
    background-color: white;
    border: 1px solid white;
}

.hamburger-menu {
    display: none;
}

@media (max-width: 1000px) {
    .navbar-name {
        display: none;
    }
    .navbar-routes {
        display: none;
    }

    .navbar-name {
        letter-spacing: normal;
    }

    .hamburger-menu {
        display: flex;
        flex-direction: column;
        float: right;
    }

    .hamburger-menu * {
        float: right;
        padding-top: 15px;
        padding-right: 15px
    }

    .hamburger-menu .link {
        position: absolute;
        padding-right: 30px;
        background-color: white;
        height: 100%;

    }

    .nav-item-mobile{
        position: absolute;
        z-index: 1;
        right: 0;
        margin-top: 3.5rem;
        width: 30%;
        transition: all 0.3s linear;
        -moz-animation-delay: 3.5s;
        -webkit-animation-delay: 3.5s;
        -o-animation-delay: 3.5s;
        animation-delay: 3.5s;
        background-color: rgba(255,255,255,1);

    }

    .nav-item-mobile > div {
        display: flex;
        flex-direction: column-reverse;
    }

    .nav-item-mobile > div * {
        padding: 10%;
        color: black;
        font-weight: bold;
        border-bottom: 1px solid black;
    }
}