.about {
    background-color:  rgba(128,128,128, 0.2);
    margin: 2%;
    padding: 2%;
    transition: all 1s ease-in;
    text-align: justify;
}

.about-description {
    color: black;
    background-color: #f6a231;
    display: inline-block;
    border-radius: 10px;
    justify-content: center;
    width: 100%;
}

.about p{
    font-size: larger;
    padding-left: 20px;
    padding-right: 20px;
}

.about-hobbies p {
    color: white;
}

.about-description span {
    font-weight: bold;
    text-decoration: underline;

}

.videos {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}



.react-player {
    width: 12.6rem !important;
    margin-left: 2%;
    margin-right: 2%;
    border: 3px solid;
    border-image: linear-gradient(to right, grey 25%, rgb(246, 162, 49) 25%, rgb(246, 162, 49) 100%,grey 100%, grey 75%, grey 75%) 5;

}

.about-hobbies {
    text-align: center;
}

.about-hobbies h1 {
    color: #f6a231;
}

@media only screen and (max-width: 1620px) {
    .react-player {
        margin-bottom: 5%;
    }
}

@media only screen and (max-width: 1000px) {
    .react-player {
        margin-bottom: 5%;
    }
}

@media only screen and (max-width: 670px) {
    .videos {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-content: stretch;
        align-items: center;
    }
    .react-player {
        margin-bottom: 5%;
    }

}