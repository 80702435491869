.author-image {
    /* the magic */
    display: block;
    width: 25px;
    height: 25px;
    overflow: hidden;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    /* the rest */
    position: relative; /* for the overlay later */
    text-align: center;
    border: 1px solid #000;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),inset 0px 3px 8px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 2px rgba(0,0,0,0.3),inset 0px 3px 8px rgba(0,0,0,0.3);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),inset 0px 3px 8px rgba(0, 0, 0, 0.3);
}

.author-image img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border: 0;
}

.item-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1%;
    padding-right: 1%;
}

.author {
    padding-left: 2%;
    display: inline-flex;
    flex-direction: row;
}

.author .name {
    font-weight: bold;
}

.author span {
    font-weight: 900;
    padding: 0 10px 0 10px;
}

.item-content{
    display: flex;
    padding-left: 1%;
    padding-right: 1%;
}

.item-content .text {
    word-break: break-word;
}

.thumbnail {
    padding-left: 5%;
}
.thumbnail img {
    width: 112px;
    aspect-ratio: auto 112 / 112;
    height: 112px;
    vertical-align: middle;
}

.blog-item {
    padding-top: 4%;
}

.blog-item:hover {
    background-color: rgba(128,128,128, 0.2);
    cursor: pointer;
}

.blog-item hr {
    width: 100%;
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}