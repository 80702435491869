.card {
    /*border: 2px solid;*/
    /*border-image: linear-gradient(to right, grey 25%, rgb(246, 162, 49) 25%, rgb(246, 162, 49) 100%,grey 100%, grey 75%, grey 75%) 5;*/
    transition: all .2s ease-in-out;
    background-color: white;
    position: relative;
}

.card:hover {
    cursor: pointer;
    box-shadow: 4px 0 15px -4px black, -6px 16px 19px -3px black;
    transform: scale(1.1);
    z-index: 1000;
}

.header {
    height: 30%;
    padding: 1%;
    /*box-shadow: 0px 1px 6px black;*/
}

.header .name{
    /*text-transform: uppercase;*/
    font-weight: 600;
}

.information p {
    text-align: left;
    padding-left: 10px;
    padding-bottom: 10px;
}

.card img {
    width: 100px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.card img:hover {
    transform: scale(1.2);
}

.information span {
    font-weight: bold;
    color: #f6a231;
}

.card {
    flex-shrink: 0;
    width: 29vw;
    height: 47vh;
    border-radius: 10px;
    margin-left: 19px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

@media only screen and (max-width: 1230px) {
    .card {
        flex-shrink: 0;
        width: 85vw;
        height: 55vh;
        border-radius: 10px;
        margin-bottom: 25px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .card:hover {
        transform: none;
    }
    .header {
        height: 25%;
        padding-top: 1px;

        /*box-shadow: 0px 1px 6px black;*/
    }

    .card img {
        width: 75px;
    }

    .information p {
        text-align: left;
        padding-left: 10px;
        padding-bottom: 5px;
        padding-right: 10px;
    }
}